class Utils {
  static extendConfs(baseObj :any, extendObj :any) :any {
    let result :any = {};
    Object.keys(baseObj).forEach((baseKey :string) => {
      if(!extendObj.hasOwnProperty(baseKey)){
        result[baseKey] = baseObj[baseKey];
        return;
      }

      if(typeof extendObj[baseKey] === 'boolean'){
        result[baseKey] = baseObj[baseKey];
        result[baseKey].init = extendObj[baseKey];
      }else{
        result[baseKey] = this.extendConf(baseObj[baseKey], extendObj[baseKey])
      }
    });
    return result;
  }

  static extendConf(baseObj: any, extendObj :any ) :any {
    let result :any = {}
    Object.keys(baseObj).forEach((baseKey) => {
      if(!extendObj.hasOwnProperty(baseKey)){
        result[baseKey] = baseObj[baseKey];
        return;
      }

      if(typeof baseObj[baseKey] !== typeof extendObj[baseKey]){
        result[baseKey] = baseObj[baseKey];
        return;
      }

      if(typeof baseObj[baseKey] === 'object' && !Array.isArray(baseObj[baseKey])){
        result[baseKey] = this.extendConf(baseObj[baseKey], extendObj[baseKey])
      }else{
        result[baseKey] = extendObj[baseKey];
      }
    });

    return result;
  }
}

export default Utils;
