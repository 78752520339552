/// <reference path="../types/config.d.ts" />

import Utils from '../utils/util';
import UWAgent from '../lib/uw-agent';
import UWPrimary from '../lib/uw-primary';

const baseConf :object = {
  uaDeviceClass: {
    init: true,
    className: {
      mobile: 'ua_sp',
      tablet: 'ua_tablet',
      pc: 'ua_pc',
    },
  },
  uaBrowserClass: {
    init: true,
    className: {
      ie: 'ua_ie',
      safari: 'ua_safari',
    }
  },
  uaOsClass: {
    init: true,
    className: {
      windows: 'ua_windows',
      ios: 'ua_ios',
    }
  },
  uaTabletViewport: {
    init: true,
    width: 1200,
  },
  uaIosCoverAdjust: {
    init: true,
    selector: '.page_cover',
  },
  toolMediaQueryReload: {
    init: true,
    width: 786,
    onlyPC: false,
  },
  toolBlankNoOpen: {
    init: true,
  },
  toolFixedHorizontalScroll: {
    init: true,
    targetSelector: '.page_header',
  },
  toolSmoothScroll: {
    init: true,
    offsetSelector: '.page_header',
    reomveClasses: ['hide'],
    landingWait: 250,
    anchorShift: true,
  },
  toolStyleVariable100vh: {
    init: true,
    elementId: 'uw-styleVariable-100vh',
    variableName: '--js100vh',
  },
  toolDeviceType: {
    init: true,
    className: {
      enableTouch: 'type_touch',
      disableTouch: '',
    }
  },
  toolHoverClass: {
    init: true,
    targetSelector: 'a, .uw-hover',
    className: {
      hover: 'onhover',
      disable: '',
    }
  },
  toolScreenRatioClass: {
    init: true,
    maxAspectRatio: 16 / 9,
    className: {
      over: 'aspect_over',
      normal: 'aspect_normal',
      landscape: 'orientation_landscape',
      portrait: 'orientation_portrait',
    },
  },
}

class UWB {
  _CONFIG :any;
  _UAInfo :UWAgent;

  constructor(conf :object = {}) {
    this._CONFIG = Utils.extendConfs(baseConf, conf);
    this._UAInfo = new UWAgent();

    if(this._CONFIG.uaDeviceClass.init){ this.uaDeviceClass() }
    if(this._CONFIG.uaBrowserClass.init) { this.uaBrowserClass() }
    if(this._CONFIG.uaOsClass.init) { this.uaOsClass() }
    if(this._CONFIG.uaTabletViewport.init) { this.uaTabletViewport() }
    if(this._CONFIG.uaIosCoverAdjust.init) { this.uaIosCoverAdjust() }
    if(this._CONFIG.toolMediaQueryReload.init) { this.toolMediaQueryReload() }
    if(this._CONFIG.toolBlankNoOpen.init) { this.toolBlankNoOpen() }
    if(this._CONFIG.toolFixedHorizontalScroll.init) { this.toolFixedHorizontalScroll() }
    if(this._CONFIG.toolSmoothScroll.init) { this.toolSmoothScroll() }
    if(this._CONFIG.toolStyleVariable100vh.init) { this.toolStyleVariable100vh() }
    if(this._CONFIG.toolDeviceType.init) { this.toolDeviceType() }
    if(this._CONFIG.toolHoverClass.init) { this.toolHoverClass() }
    if(this._CONFIG.toolScreenRatioClass.init) { this.toolScreenRatioClass() }
  }

  get config() {
    return this._CONFIG;
  }
  get UAInfo() {
    return this._UAInfo;
  }

  uaDeviceClass() :void {
    this._UAInfo.setDeviceClass(this._CONFIG.uaDeviceClass);
  }
  uaBrowserClass() :void {
    this._UAInfo.setBrowserClass(this._CONFIG.uaBrowserClass);
  }
  uaOsClass() :void {
    this._UAInfo.setOsClass(this._CONFIG.uaOsClass);
  }
  uaTabletViewport() :void {
    this._UAInfo.setTabletViewport(this._CONFIG.uaTabletViewport);
  }
  uaIosCoverAdjust() :void {
    this._UAInfo.setIosCoverAdjust(this._CONFIG.uaIosCoverAdjust);
  }
  toolMediaQueryReload(): void {
    if(!this._CONFIG.toolMediaQueryReload.onlyPC || this._UAInfo.isPC()){
      UWPrimary.setMediaQueryAction(this._CONFIG.toolMediaQueryReload, () => {
        location.reload();
      });
    }
  }
  toolBlankNoOpen() :void {
    UWPrimary.setBlankNoOpen(this._CONFIG.toolBlankNoOpen);
  }
  toolFixedHorizontalScroll() :void {
    UWPrimary.setFixedHorizontalScroll(this._CONFIG.toolFixedHorizontalScroll);
  }
  toolSmoothScroll() :void {
    UWPrimary.setSmoothScroll(this._CONFIG.toolSmoothScroll);
  }
  toolStyleVariable100vh() :void {
    const resizeEnable = !(this._UAInfo.isIos() && this._UAInfo.isSafari());
    UWPrimary.setStyleVariable100vh(this._CONFIG.toolStyleVariable100vh, resizeEnable);
  }
  toolDeviceType() :void {
    UWPrimary.setDeviceType(this._CONFIG.toolDeviceType);
  }
  toolHoverClass() :void {
    UWPrimary.setHoverClass(this._CONFIG.toolHoverClass);
  }
  toolScreenRatioClass() :void {
    UWPrimary.setScreenRatioClass(this._CONFIG.toolScreenRatioClass);
  }
}

export default UWB;
