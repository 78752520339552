import * as UAP from 'ua-parser-js';

class UWAgent{
  readonly UAResult :UAParser.IResult;
  readonly ipadSpecial :boolean;

  constructor(){
    const raw_ua :string = window.navigator.userAgent.toLowerCase();
    this.UAResult = (new UAP.UAParser()).getResult();
    this.ipadSpecial = raw_ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
  }

  isPC() :boolean {
    return this.UAResult.device.type === undefined;
  }
  isTablet() :boolean {
    return (this.UAResult.device.type === 'tablet') || this.ipadSpecial;
  }
  isMobile() :boolean {
    return this.UAResult.device.type === 'mobile';
  }
  isIE() :boolean {
    return this.UAResult.browser.name === 'IE';
  }
  isSafari() :boolean {
    return (this.UAResult.browser.name as string).includes('Safari');
  }
  isWindows() :boolean {
    return (this.UAResult.os.name as string).startsWith('Windows');
  }
  isIos() :boolean {
    return this.UAResult.os.name as string === 'iOS';
  }

  setDeviceClass(conf :UaDeviceClassConf) :void {
    if(this.isTablet()){
      document.body.classList.add(conf.className.tablet);
    }
    if(this.isMobile() && !this.isTablet()){
      document.body.classList.add(conf.className.mobile);
    }
    if(this.isPC() && !this.isMobile() && !this.isTablet()){
      document.body.classList.add(conf.className.pc);
    }
  }
  setBrowserClass(conf :UaBrowserClassConf) :void {
    if(this.isIE()){
      document.body.classList.add(conf.className.ie);
    }
    if(this.isSafari()){
      document.body.classList.add(conf.className.safari);
    }
  }
  setOsClass(conf :UaOsClassConf) :void {
    if(this.isWindows()){
      document.body.classList.add(conf.className.windows);
    }
    if(this.isIos()){
      document.body.classList.add(conf.className.ios);
    }
  }
  setTabletViewport(conf :UaTabletViewportConf) :void {
    const width = conf.width || 1200;
    if(this.isTablet()){
      const meta :HTMLElement | null = document.querySelector("meta[name='viewport']");
      meta?.setAttribute('content', 'width=' + width);
    }
  }
  setIosCoverAdjust(conf :UaIosCoverAdjustConf) {
    if(this.UAResult.browser.name === 'iOS'){
      const target = document.querySelector(conf.selector) as HTMLElement;
      if(target){
        target.style.height = `${window.innerHeight}px`;

        window.addEventListener('resize', () => {
          target.style.height = `${window.innerHeight}px`;
        });
      }
    }
  }
}

export default UWAgent;
