import UWTool from '../lib/uw-tool';

class UWPrimary {
  static setMediaQueryAction(conf :ToolMediaQueryReloadConf, func :() => void) :void {
    const mql = window.matchMedia(`screen and (max-width: ${conf.width}px)`);
    mql.addListener(func);
  }

  static setBlankNoOpen(conf :ToolBlankNoOpenConf) :void {
    const blanks = document.querySelectorAll('a[target="_blank"]');
    Array.from(blanks).forEach((blank) => {
      blank.setAttribute('rel', 'noopener');
    });
  }

  static setFixedHorizontalScroll(conf :ToolFixedHeaderHorizontalScrollConf) {
    let lastScrollX = 0;
    window.addEventListener('scroll', () => {
      if(lastScrollX === window.scrollX){
        return;
      }

      lastScrollX = window.scrollX;
      const targets = Array.from(document.querySelectorAll(`${conf.targetSelector}`)) as Array<HTMLElement>
      targets.forEach(target => {
        const targetPosition = window.getComputedStyle(target).getPropertyValue('position')
        
        if(targetPosition === 'fixed'){
          target.style.setProperty('left', `${-window.scrollX}px`);
        }else{
          target.style.setProperty('left', `0px`);
        }
      })
    });
  }

  static setSmoothScroll (conf :ToolSmoothScrollConf) {
    const getHeaderOffset = () => {
      if(conf.offsetSelector.length === 0 || document.querySelector(conf.offsetSelector) === null){
        return 0;
      }else{
        const header = document.querySelector(conf.offsetSelector) as HTMLElement;
        return header.getBoundingClientRect().height;
      }
    }

    if(conf.anchorShift && location.hash.length !== 0){
      setTimeout(() => {
        const anchorElement = document.querySelector(location.hash)
        const anchorTop = (anchorElement as HTMLElement).getBoundingClientRect().top
        const top = anchorTop + scrollY - getHeaderOffset();
        scrollTo({
          top,
        })
      }, 50)
    }

    let links = document.querySelectorAll('a[href^="#"]');
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', (e) => {
        e.preventDefault();
        const targetId = (links[i] as HTMLAnchorElement).hash;
        const targetElement = document.querySelector(targetId);
        const rectTop = (targetElement as HTMLElement).getBoundingClientRect().top;
        const top = rectTop + scrollY - getHeaderOffset();
  
        history.pushState(null, '', targetId)
        window.scrollTo({
          top,
          behavior: "smooth"
        })
        
        const isInvalid = conf.offsetSelector.length === 0 || document.querySelector(conf.offsetSelector) === null
        if(!isInvalid && conf.reomveClasses.length !== 0){
          const dispatchScrollEnd = setInterval(() => {
            if(top - 10 < window.pageYOffset){
              clearInterval(dispatchScrollEnd);
              setTimeout(() => {
                if(!isInvalid){
                  const header = document.querySelector(conf.offsetSelector) as HTMLElement;
                  header.classList.remove(...conf.reomveClasses);
                }
              }, conf.landingWait);
            }
          }, 50);
        }
      });
    }
  }
  static setStyleVariable100vh (conf :ToolStyleVariable100vhConf, resizeEnable = true) {
    UWTool.setStyleVariable(conf.elementId, {
      [conf.variableName]: `${window.innerHeight}px`
    });
    if(resizeEnable){
      window.addEventListener('resize', () => {
        UWTool.setStyleVariable(conf.elementId, {
          [conf.variableName]: `${window.innerHeight}px`
        });
      });
    }

  }
  static setDeviceType (conf :ToolDeviceTypeConf) {
    const isTouch = 'ontouchstart' in window;
    if(conf.className.enableTouch.length !== 0 && isTouch){
      document.body.classList.add(conf.className.enableTouch);
    }
    if(conf.className.disableTouch.length !== 0 && !isTouch){
      document.body.classList.add(conf.className.disableTouch);
    }
  }
  static setHoverClass (conf :ToolHoverClassConf){
    const isTouch = 'ontouchstart' in window;
    if(isTouch){
      return;
    }

    const targets = document.querySelectorAll(conf.targetSelector);
    Array.from(targets).forEach(t => {
      t.addEventListener('mouseover', () => {
        if(!t.classList.contains(conf.className.disable)){
          t.classList.add(conf.className.hover);
        }
      })
      t.addEventListener('mouseleave', () => {
        t.classList.remove(conf.className.hover);
      });
    });
  }

  static setScreenRatioClass (conf :ToolScreenRatioClass){
    let isAspectNormal :boolean
    let isLandscape :boolean

    const resetAspectRetio = () => {
      const aspect = window.innerWidth / window.innerHeight
      if(conf.maxAspectRatio < aspect){
        isAspectNormal = false
        conf.className.over.length !== 0 && document.body.classList.add(conf.className.over)
        conf.className.normal.length !== 0 && document.body.classList.remove(conf.className.normal)
      }else{
        isAspectNormal = true
        conf.className.over.length !== 0 && document.body.classList.remove(conf.className.over)
        conf.className.normal.length !== 0 && document.body.classList.add(conf.className.normal)
      }
    }

    const resetOrientation = () => {
      if(window.innerWidth < window.innerHeight){
        isLandscape = false
        conf.className.portrait.length !== 0 && document.body.classList.add(conf.className.portrait)
        conf.className.landscape.length !== 0 && document.body.classList.remove(conf.className.landscape)
      }else{
        isLandscape = true
        conf.className.portrait.length !== 0 && document.body.classList.remove(conf.className.portrait)
        conf.className.landscape.length !== 0 && document.body.classList.add(conf.className.landscape)
      }
    }

    const resizeObs = new ResizeObserver(() => {
      const aspectNormal = (window.innerWidth / window.innerHeight) < conf.maxAspectRatio
      const landscape = window.innerHeight < window.innerWidth

      if(isAspectNormal !== aspectNormal){
        resetAspectRetio()
      }

      if(isLandscape !== landscape){
        resetOrientation()
      }

    })
    resizeObs.observe(document.body as HTMLElement, {box: 'border-box'})
  }
}

export default UWPrimary;
